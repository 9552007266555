import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import {
  OnboardingCreationRequestData,
  OnboardingCreationStatusWritings,
  OnboardingCreationWorkflow,
} from '@dougs/onboarding-creation/dto';

@Injectable({
  providedIn: 'root',
})
export class OnboardingCreationHttpService {
  constructor(private readonly http: HttpClient) {}

  getOnboarding(userId: number): Observable<OnboardingCreationWorkflow> {
    return this.http.get<OnboardingCreationWorkflow>(
      `${AppConfig.settings.legacyApiServerUrl}/legal-creation-onboarding-api/workflow/${userId}`,
    );
  }

  getStatusWritings(companyId: number): Observable<OnboardingCreationStatusWritings> {
    return this.http.get<OnboardingCreationStatusWritings>(
      `${AppConfig.settings.legacyApiServerUrl}/onboarding-creation/status-writings/${companyId}`,
    );
  }

  updateOnboarding(userId: number, onboarding: OnboardingCreationRequestData): Observable<OnboardingCreationWorkflow> {
    return this.http
      .patch<{
        data: OnboardingCreationWorkflow;
      }>(`${AppConfig.settings.legacyApiServerUrl}/legal-creation-onboarding-api/workflow/${userId}`, onboarding)
      .pipe(map((result) => result.data));
  }

  requestLegalMeetingQuote(userId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${userId}/legal-creation-onboarding/transfer-to-legal`,
      null,
    );
  }
}
